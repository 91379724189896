class AtModalInstance {
  constructor(modalId) {
    this.onCloseCallbacks = []
  }

  onClose(func) {
    this.onCloseCallbacks.push(func);
  }

  _close() {
    this.onCloseCallbacks.forEach((func) => {
      func();
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const atModalList = document.querySelectorAll('*[id^="at-modal-"]');
  const atModalsById = {};
  const atTriggerList = document.querySelectorAll('*[class*="at-modal-trigger"]')
  let activeModal = null;
  let defaultBodyOverflow = null;

  atModalList.forEach((modalEl) => {
    initializeAtModal(modalel.id)
  })

  atTriggerList.forEach((triggerEl) => {
    const triggerClass = Array.from(triggerEl.classList).find((className) => {
      return className.startsWith('at-modal-trigger-');
    })

    const modalId = triggerClass.replace('-trigger', '');

    triggerEl.addEventListener('click', () => {
      openAtModal(modalId);
    });
  });

  window.initializeAtModal = function(modalId) {
    const modalEl          = document.getElementById(modalId);
    const modalBackdrop    = document.createElement('div');
    const modalBodyWrapper = document.createElement('div');
    const modalBody        = document.createElement('div');
    const modalEscape      = document.createElement('button');
    const modalContent     = document.createElement('div')

    atModalsById[modalId] = modalEl;

    modalBackdrop.classList.add('at-modal-backdrop');
    modalBodyWrapper.classList.add('at-modal-body-wrapper');
    modalBody.classList.add('at-modal-body');
    modalContent.classList.add('at-modal-content');

    modalEscape.type = 'button';

    modalBackdrop.appendChild(modalBodyWrapper);
    modalBodyWrapper.appendChild(modalBody);
    modalBody.appendChild(modalEscape);
    modalBody.appendChild(modalContent);
    
    Array.from(modalEl.children).forEach(childEl => {
      modalContent.appendChild(childEl);
    });

    modalEl.appendChild(modalBackdrop);
    modalEscape.addEventListener('click', () => {
      closeAtModal(modalEl.id);
    });

    modalBodyWrapper.addEventListener('click', (event) => {
      if (event.target != modalBodyWrapper) { return; }

      closeAtModal(modalEl.id);
    });
  }

  window.openAtModal = function(modalId) {
    const modal = atModalsById[modalId];

    if (!modal) { return; }

    const body = document.getElementsByTagName('body')[0];

    modal.style.display = 'block';
    defaultBodyOverflow = body.style.overflow;
    body.style.overflow = 'hidden';

    activeModal = new AtModalInstance();

    setTimeout(() => {
      modal.style.opacity = 1;
    });

    return activeModal;
  }

  window.closeAtModal = function(modalId) {
    const modal = atModalsById[modalId];

    if (!modal) { return; }

    const body = document.getElementsByTagName('body')[0];

    modal.style.opacity = 0.01;
    body.style.overflow = defaultBodyOverflow;
    defaultBodyOverflow = null;

    activeModal._close();
    activeModal = null;

    setTimeout(() => {
      modal.style.display = 'none';
    }, 500); // time needs to match transition duration in css file
  }

  document.addEventListener('keyup', (event) => {
    if (event.key != 'Escape') { return; }

    for ([modalId, modalEl] of Object.entries(atModalsById)) {
      if (modalEl.style.display !== 'block') { return; }
      closeAtModal(modalId);
    }
  });
})
